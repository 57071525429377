<template>
  <div :class="classRoot">
    <div class="card card-fullheight" :class="{ 'card-black': black }" >
      <div class="card-title" v-if="$slots.title">
        <slot name="title"></slot>
      </div>
      <div class="card-body">
        <slot></slot>
      </div>
      <div class="card-legend" v-if="$slots.legend">
        <slot name="legend"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    black: {
      type: Boolean,
      default: false,
      required: false
    },
    showBorder: {
      type: Boolean,
      default: true
    },
    classRoot: {
      type: [String, Object, Array],
      default: 'card-fullheight'
    }
  }
}
</script>

<style scoped>
  .card-black {
    background-color: #3D3D3D;
  }

  .card {
    margin-left: 5px;
    padding: 16px 24px;
  }

  .card-title, .card-title b {
    margin: 0;
    padding: 0;
    text-align: left;
  }
</style>
