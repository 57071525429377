<template>
  <div class="button-back">
    <a href="javascript:history.back()"><i style="color: #f1f1f1;" class="ft-chevron-left"></i></a>
    <div class="title"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'ButtonBack',
  props: {
    classOut: {
      type: String,
      required: false
    }
  }
}

</script>

<style scoped>
  .button-back{
    display: flex;
    justify-content: initial;
    align-items: center;
    width: 100%;
    margin-top: -3%;
    padding-bottom: 20px;
  }

  .button-back a{
    width: 35px;
    height: 35px;
    background-color: #84754E;
    color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .title {
    line-height: 35px;
    margin-left: 10px;
  }
</style>
