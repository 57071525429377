<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'quarter'}" v-on:click="setTempPeriod('quarter')" ref="view_type_quarter"  data-toggle="pill" data-period="quarter">{{ $t('period.quarter') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Vendedor -->
              <!--<h6 class="mb-3 mt-5">{{ $t('form.report.seller') }}</h6>-->
              <div class="mt-5">
                  <div class="md-form mb-0 ">
                    <div class="md-form mb-0">
                      <select class="md-form-control" :value="filters.filEmployee" id="employee" name="employee" ref="employee">
                        <option v-for="(item, index) in sellerList" v-bind:key="index" :value="item.id"  :selected="filters.filEmployee === item.id" >{{ item.nome }}</option>
                      </select>
                      <label :class="{'active': true}">{{ $t('form.report.seller') }}</label>
                    </div>
                  </div>
              </div>

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in $util.years(2021)" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'quarter'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filQuarter" id="quarter" name="quarter" ref="quarter" >
                          <option v-for="(quarter, index) in $util.quarters()" v-bind:key="index" :value="index" :selected="filters.filQuarter === parseInt(index)" >{{ quarter }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.quarter') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in $util.months()" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-10 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList()" />
      </div>

      <div class="col-12">
        <button-back>
          ACESSOS DO PORTAL
        </button-back>
      </div>

<!--      &lt;!&ndash; Queries &ndash;&gt;-->
<!--      <card class-root="col-12" v-if="showQueries">-->
<!--        <div class="row">-->
<!--          <div class="col-12 mb-5" v-for="(query, index) in reportData.queries" v-bind:key="index">-->
<!--            <div class="title">{{ index.toUpperCase() }}</div>-->
<!--            <div >-->
<!--              <pre>{{ query.sql }}</pre>-->
<!--              <table class="table table-striped" v-if="query.params">-->
<!--                <thead>-->
<!--                    <tr>-->
<!--                  <td>Nome</td>-->
<!--                  <td>Valor</td>-->
<!--                    </tr>-->
<!--                </thead>-->
<!--                <tr v-for="(param, index) in query.params" :key="index">-->
<!--                  <td>{{ param.name }}</td>-->
<!--                  <td>{{ param.value }}</td>-->
<!--                </tr>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </card>-->

      <!-- Total Usuários Ativos -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="total.qtd_acessos"
                 :hide-goal="true"
                 :black="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
      >
        <template slot="title">
          Acessos nos últimos 7 dias
        </template>
      </card-goal>

      <!-- Usuários Ativos -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="total.qtd_usuario_ativos"
                 :hide-goal="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
      >
        <template slot="title">
          Usuários Ativos
        </template>
      </card-goal>

      <!-- Moderadores -->
      <card-goal class-root="col-12 col-md-3 mb-4"
          :realizado="total.qtd_moderadores"
          :hide-goal="true"
          :decimals="2"
          :meta="0"
          :label-realizado="''"
          >
        <template slot="title">
          Moderadores
        </template>
      </card-goal>

      <!-- Fatures Online -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="total.qtd_faturas_online"
                 :hide-goal="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
      >
        <template slot="title">
          Faturas Online
        </template>
      </card-goal>

      <!-- Cotações Online -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="total.qtd_cotacoes_online"
                 :hide-goal="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
      >
        <template slot="title">
          Cotações Online
        </template>
      </card-goal>

      <card class-root="col-12 mt-2" >
        <div class="row">
          <div class="col-12 mb-5">
            <custom-vue-table
              ref="OriginDestinationTable"
              http-method="get"
              :multi-sort="true"
              :api-url="loadEndpoint"
              :fields="fieldsTable"
              :filter-fields="filterFields"
              :sort-order="sortOrder"
              @on-pagination-data="onPaginationData"
              track-by="id"
            >
            </custom-vue-table>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Card from '@/views/Report/Seller/2022/Card'
import CardGoal from '@/views/Report/Seller/2022/CardGoal'
import ButtonBack from '@/components/Navigate/ButtonBack'
import CustomVueTable from '@/components/Table/CustomVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import EmployeeService from '@/services/EmployeeService'
// import SolidDonutChart from '@/components/Chart/amcharts4/SolidDonutChart'

export default {
  name: 'ReportProcessAccess',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.eroyal.portalAccess.title') + ' ' + this.$i18n.t('report.eroyal.title') + ' - %s'
    }
  },
  data () {
    return {
      filterFields: [
        {
          name: 'empresa',
          title: 'Empresa',
          type: 'text',
          value: null
        },
        {
          name: 'email',
          title: 'E-mail',
          type: 'text',
          value: null
        },
        {
          name: 'ativo',
          title: 'Ativo',
          type: 'options',
          options: {
            '': '',
            'Sim': 'Sim',
            'Não': 'Não'
          },
          value: null
        },
        {
          name: 'moderador',
          title: 'Moderador',
          type: 'options',
          options: {
            '': '',
            'Sim': 'Sim',
            'Não': 'Não'
          },
          value: null
        },
        {
          name: 'fatura_online',
          title: 'Faturas Online',
          type: 'options',
          options: {
            '': '',
            'Sim': 'Sim',
            'Não': 'Não'
          },
          value: null
        },
        {
          name: 'cotacoes_online',
          title: 'Cotações Online',
          type: 'options',
          options: {
            '': '',
            'Sim': 'Sim',
            'Não': 'Não'
          },
          value: null
        }
        // {
        //   name: 'contratou_rod_nac',
        //   title: 'Contr. Rod. Nac,',
        //   type: 'boolean',
        //   value: null
        // }
      ],
      total: {
        total: 0,
        qtd_acessos: 0,
        qtd_moderadores: 0,
        qtd_usuario_ativos: 0,
        qtd_cotacoes_online: 0,
        qtd_faturas_online: 0
      },
      loadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/report/portal-access',
      sortOrder: [
        { field: 'ultimo_acesso', direction: 'desc' }
      ],
      showQueries: false,
      valor: 10.0,
      customerList: [],
      sellerList: [],
      tempPeriod: 'monthly',
      seller: {},
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {}
    }
  },
  components: {
    CustomVueTable,
    Card,
    CardGoal,
    FilterSideBar,
    Loading,
    FilterContainer,
    ButtonBack
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getReportSellerData()
    }
    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    onPaginationData (pagination) {
      this.total.total = pagination.total
      this.total.qtd_acessos = pagination.qtd_acessos
      this.total.qtd_moderadores = pagination.qtd_moderadores
      this.total.qtd_usuario_ativos = pagination.qtd_usuario_ativos
      this.total.qtd_cotacoes_online = pagination.qtd_cotacoes_online
      this.total.qtd_faturas_online = pagination.qtd_faturas_online
    },
    toogleQueries () {
      this.showQueries = !this.showQueries
    },
    tableHeaders () {
      let headers = [
        {
          title: 'Q1',
          period: 1
        },
        {
          title: 'Q2',
          period: 2
        },
        {
          title: 'Q3',
          period: 3
        },
        {
          title: 'Q4',
          period: 4
        }
      ]

      if (this.filters.filPeriodo === 'monthly') {
        headers = []
        for (let i = 1; i <= 12; i++) {
          headers.push(
            {
              title: this.$i18n.t('form.goal.idFrequency.monthly.' + i),
              period: i
            }
          )
        }
      }

      return headers
    },
    getValueByPeriod (indicator, attribute, filters, period) {
      let type = filters.filPeriodo
      let value = 0

      if (type === 'monthly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]

          if (period === item.month) {
            value = item[attribute]
            break
          }
        }
      } else if (type === 'quarter') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          let quarter = parseInt(Math.ceil((item.month) / 3))
          if (period === quarter) {
            value += item[attribute]
          }
        }
      } else if (type === 'yearly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          value += item[attribute]
        }
      }

      return this.numberFormat(value, 0, ',', '.')
    },
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      }

      return activeFilterList
    },
    getTitle () {
      let title = ''

      if (this.filters.filPeriodo === 'monthly') {
        title = 'RESULTADO MENSAL'
      } else if (this.filters.filPeriodo === 'quarter') {
        title = 'RESULTADO TRIMESTRAL'
      } else if (this.filters.filPeriodo === 'yearly') {
        title = 'RESULTADO ANUAL'
      }

      return title
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getPercentMark (value) {
      let goalOnPeriod = 1
      let percentMark = 100

      if (value.goal > 0) {
        goalOnPeriod = value.goal * 1.0
      }
      percentMark = (value.goal * 100 / goalOnPeriod)

      return percentMark
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))

      return quarter
    },
    getPercentualInsuranceGoal (totalFiles, percent) {
      let totalPercent = 0

      if (totalFiles) {
        totalPercent = totalFiles * percent / 100
      }

      return totalPercent
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Senão filtro pelo primeiro vendedor da lista
        this.filters.filEmployee = this.sellerList[0].id
      }

      this.filter.employee = this.filters.filEmployee
      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getReportSellerData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // filtro pelo primeiro vendedor da lista
        this.filters.filEmployee = this.sellerList[0].id
      }

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportSellerData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showNumber(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (thousandsSep !== '') {
        thousandsSep = thousandsSep || '.'
      }

      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showIntegerGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showInteger(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    getReportSellerData () {
      let me = this
      this.isLoading = true
      if (this.$store.getters.userInfo) {
        EmployeeService.getSellers([]).then(res => {
          me.sellerList = res.data.data
        }).finally(() => {
          me.isLoading = false
        })
      }
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'empresa',
          title: 'Empresa',
          sortField: 'empresa',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'usuario',
          title: 'Usuário',
          width: '20%',
          sortField: 'usuario',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: 'E-mail',
          width: '20%',
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'ultimo_acesso',
          title: 'Último acesso',
          sortField: 'ultimo_acesso',
          formatter: (value) => this.$util.formatDateTime(value)
        },
        {
          name: 'ativo',
          title: 'Ativo',
          width: '7%',
          titleClass: 'text-center',
          dataClass: 'text-center',
          sortField: 'ativo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'moderador',
          title: 'Moderador',
          width: '7%',
          titleClass: 'text-center',
          dataClass: 'text-center',
          sortField: 'moderador',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'fatura_online',
          title: 'Faturas Online',
          sortField: 'fatura_online',
          width: '7%',
          titleClass: 'text-center',
          dataClass: 'text-center',

          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'cotacoes_online',
          title: 'Cotações Online',
          sortField: 'cotacoes_online',
          width: '7%',
          titleClass: 'text-center',
          dataClass: 'text-center',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    },
    activeFilters () {
      return this.filters
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .subtitle {
    margin-top: 3.0rem;
    margin-bottom: 0.8rem;
  }

  .query-info {
    position: absolute;
    top: 26px;
    right: 36px;
  }
</style>
